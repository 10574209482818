


import FETCHING from '../../library/fetching'
import DATA_MASTER from '../../library/dataMaster'

export default {
  data() {
    return {

      form : {
        id : '',
        nik : '',
        nama_lengkap : '',
        tmp_lahir : '',
        tgl_lahir : '',
        jns_kelamin : '',
        alamat : '',
        dusun : '',
        rt_rw : '',
        kecamatan_id : '',
        des_kel_id : '',
        telp_hp : '',
        email : '',
        kode_pos : '',
        status_perkawinan_id : '',
        uraian_perkawinan : '',
        agama_id : '',
        uraian_agama : '',
        tinggi_bdn : '',
        berat_bdn : '',
        file : null,

        username : '',
        nama : '',
      },

      // ====================================== CONTOH AUTOCOMPLETE ====================================
      autocomplete_db : '',
      listKecamatan : '',
      listDesKel : '',
      // ====================================== CONTOH AUTOCOMPLETE ====================================
      


     
      list_data : [],
      list_desa : [],

      page_first: 1,
      page_last: 0,
      page_limit: 10,
      cari_value : "",
      file_old : "",
      cek_load_data : true,
      file_path: this.$store.state.url.URL_APP + "uploads/",

      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      mdl_lihat : false,
      btn_add: false,


      FETCHING : FETCHING,
      DATA_MASTER : DATA_MASTER,
    }
  },
  methods: {
    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_BIO_KETERANGAN_UMUM + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value,
              kecamatan_id : this.form.kecamatan_id,
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.$store.commit("hideLoading");
              // console.log(res_data);
      });
    },


    addData : function() {
      var formData = new FormData();
      formData.append("nik", this.form.nik);
      // formData.append("nama_lengkap", this.form.nama_lengkap);
      formData.append("tmp_lahir", this.form.tmp_lahir);
      formData.append("tgl_lahir", this.form.tgl_lahir);
      formData.append("jns_kelamin", this.form.jns_kelamin);
      formData.append("alamat", this.form.alamat);
      formData.append("dusun", this.form.dusun);
      formData.append("rt_rw", this.form.rt_rw);
      formData.append("kecamatan_id", this.form.kecamatan_id);
      formData.append("des_kel_id", this.form.des_kel_id);
      // formData.append("telp_hp", this.form.telp_hp);
      // formData.append("email", this.form.email);
      formData.append("kode_pos", this.form.kode_pos);
      formData.append("status_perkawinan_id", this.form.status_perkawinan_id);
      formData.append("agama_id", this.form.agama_id);
      formData.append("tinggi_bdn", this.form.tinggi_bdn);
      formData.append("berat_bdn", this.form.berat_bdn);
      formData.append("file", this.form.file);

      fetch(this.$store.state.url.URL_BIO_KETERANGAN_UMUM + "addData", {
          method: "POST",
          headers: {
            // "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: formData
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getView();
      });
    },


    editData : function(){
      var formData = new FormData();
      formData.append("id", this.form.id);
      formData.append("nik", this.form.nik);
      // formData.append("nama_lengkap", this.form.nama_lengkap);
      formData.append("tmp_lahir", this.form.tmp_lahir);
      formData.append("tgl_lahir", this.form.tgl_lahir);
      formData.append("jns_kelamin", this.form.jns_kelamin);
      formData.append("alamat", this.form.alamat);
      formData.append("dusun", this.form.dusun);
      formData.append("rt_rw", this.form.rt_rw);
      formData.append("kecamatan_id", this.form.kecamatan_id);
      formData.append("des_kel_id", this.form.des_kel_id);
      // formData.append("telp_hp", this.form.telp_hp);
      // formData.append("email", this.form.email);
      formData.append("kode_pos", this.form.kode_pos);
      formData.append("status_perkawinan_id", this.form.status_perkawinan_id);
      formData.append("agama_id", this.form.agama_id);
      formData.append("tinggi_bdn", this.form.tinggi_bdn);
      formData.append("berat_bdn", this.form.berat_bdn);
      formData.append("file", this.form.file);
      formData.append('file_old', this.file_old);

      fetch(this.$store.state.url.URL_BIO_KETERANGAN_UMUM + "editData", {
          method: "POST",
          headers: {
            // "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: formData
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
      });
    },

    removeData : function(idnya, file){
      fetch(this.$store.state.url.URL_BIO_KETERANGAN_UMUM + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            id : idnya,
            file : file, 
          })
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
      });

    },

    selectData : function(data){
      this.form.id = data.id;
      this.form.nik = data.nik;
      // this.form.nama_lengkap = data.nama_lengkap;
      this.form.tmp_lahir = data.tmp_lahir;
      this.form.tgl_lahir = data.tgl_lahir;
      this.form.jns_kelamin = data.jns_kelamin;
      this.form.alamat = data.alamat;
      this.form.dusun = data.dusun;
      this.form.rt_rw = data.rt_rw;
      this.form.kecamatan_id = data.kecamatan_id;
      this.form.nama_kecamatan = data.nama_kecamatan;
      this.form.des_kel_id = data.des_kel_id;
      this.form.nama_des_kel = data.nama_des_kel;
      // this.form.telp_hp = data.telp_hp;
      // this.form.email = data.email;
      this.form.kode_pos = data.kode_pos;
      this.form.status_perkawinan_id = data.status_perkawinan_id;
      this.form.uraian_perkawinan = data.uraian_perkawinan;
      this.form.agama_id = data.agama_id;
      this.form.uraian_agama = data.uraian_agama;
      this.form.tinggi_bdn = data.tinggi_bdn;
      this.form.berat_bdn = data.berat_bdn;
      this.form.file = data.file;
      this.file_old = data.file;

      this.form.username = data.username;
      this.form.nama = data.nama;
      this.form.email = data.email;
      this.form.hp = data.hp;
    },


    // ====================================== CONTOH eDOC ====================================
    onClickx(data){
      alert(data)
    },
    // ====================================== CONTOH eDOC ====================================

  // ====================================== CONTOH AUTOCOMPLETE ====================================
    filter_jenis : function (val, update) {
        update(() => {
          if (val === '') {}
          else {FETCHING.getJenis(val)}
        })
    },

    // filter_des_kel : function (kecamatan_id, update) {
    //     update(() => {
    //       if (kecamatan_id === '') {}
    //       else {FETCHING.getDesKel(this.form.kecamatan_id)}
    //     })
    // },

    // ====================================== CONTOH AUTOCOMPLETE ====================================








    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },
        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx;
        },
        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },

        async awaitFetch(){
          this.$store.state.list_des_kel = await this.DATA_MASTER.getDesKel(this.form.kecamatan_id);
        }


    // ====================================== PAGINATE ====================================

  },

  mounted () {
    var get_profile = JSON.parse(localStorage.profile);
    this.form.username = get_profile.profile.username;
    this.form.nama = get_profile.profile.nama;
    this.form.hp = get_profile.profile.hp;
    this.form.email = get_profile.profile.email;
    DATA_MASTER.getAgama();
    DATA_MASTER.getStatusPerkawinan();
    DATA_MASTER.getKecamatan();
    DATA_MASTER.getBahasa();
    this.getView();
  },
}
