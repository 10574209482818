// import {store1} from '../store/index's

var DataStore = require('../store');
var store = DataStore.default

const fetchPOST = () => {
  return store.state.kamio
}

const getAgama = () => {
  fetch(store.state.url.URL_DM_AGAMA + "list", {
      method : 'POST',
      headers : {
        "content-type": "application/json",
        authorization: "kikensbatara " + localStorage.token
      },
      body : JSON.stringify({
        // unit_kerja : 'unit_kerja'

      })
  }).then((res) => res.json()).then((res_data) => {
    store.state.list_agama = res_data
    // console.log(res_data)
  })
}

const getStatusPerkawinan = () => {
  fetch(store.state.url.URL_DM_STATUS_PERKAWINAN + "list", {
      method : 'POST',
      headers : {
        "content-type": "application/json",
        authorization: "kikensbatara " + localStorage.token
      },
      body : JSON.stringify({
        // unit_kerja : 'unit_kerja'

      })
  }).then((res) => res.json()).then((res_data) => {
    store.state.list_status_perkawinan = res_data
    // console.log(res_data)
  })
}

const getBahasa = () => {
  fetch(store.state.url.URL_DM_BAHASA_ASING + "list", {
      method : 'POST',
      headers : {
        "content-type": "application/json",
        authorization: "kikensbatara " + localStorage.token
      },
      body : JSON.stringify({
        // unit_kerja : 'unit_kerja'

      })
  }).then((res) => res.json()).then((res_data) => {
    store.state.list_bahasa = res_data
    // console.log(res_data)
  })
}

const getBesaranUpah = () => {
  fetch(store.state.url.URL_DM_BESARAN_UPAH + "list", {
      method : 'POST',
      headers : {
        "content-type": "application/json",
        authorization: "kikensbatara " + localStorage.token
      },
      body : JSON.stringify({
        // unit_kerja : 'unit_kerja'

      })
  }).then((res) => res.json()).then((res_data) => {
    store.state.list_upah = res_data
    // console.log(res_data)
  })
}

const getKecamatan = () => {
  fetch(store.state.url.URL_DM_KECAMATAN + "list", {
      method : 'POST',
      headers : {
        "content-type": "application/json",
        authorization: "kikensbatara " + localStorage.token
      },
      body : JSON.stringify({
        

      })
  }).then((res) => res.json()).then((res_data) => {
    store.state.list_kecamatan = res_data
    // console.log(res_data)
  })
}

const getDesKel = (id) => {
  fetch(store.state.url.URL_DM_DES_KEL + "list", {
      method : 'POST',
      headers : {
        "content-type": "application/json",
        authorization: "kikensbatara " + localStorage.token
      },
      body : JSON.stringify({
        kecamatan_id : id,

      })
  }).then((res) => res.json()).then((res_data) => {
    store.state.list_des_kel = res_data
    // console.log(res_data)
  })
}

const getPendidikan = () => {
  fetch(store.state.url.URL_DM_PENDIDIKAN + "list", {
      method : 'POST',
      headers : {
        "content-type": "application/json",
        authorization: "kikensbatara " + localStorage.token
      },
      body : JSON.stringify({
        // unit_kerja : 'unit_kerja'

      })
  }).then((res) => res.json()).then((res_data) => {
    store.state.list_pendidikan = res_data
    // console.log(res_data)
  })
}

const getJurusan = (id) => {
  fetch(store.state.url.URL_DM_JURUSAN + "list", {
      method : 'POST',
      headers : {
        "content-type": "application/json",
        authorization: "kikensbatara " + localStorage.token
      },
      body : JSON.stringify({
        pendidikan_id : id,

      })
  }).then((res) => res.json()).then((res_data) => {
    store.state.list_jurusan = res_data
    console.log(res_data)
  })
}

const getBidang = () => {
  fetch(store.state.url.URL_DM_BIDANG + "list", {
      method : 'POST',
      headers : {
        "content-type": "application/json",
        authorization: "kikensbatara " + localStorage.token
      },
      body : JSON.stringify({
        // unit_kerja : 'unit_kerja'

      })
  }).then((res) => res.json()).then((res_data) => {
    store.state.list_bidang = res_data
    // console.log(res_data)
  })
}


module.exports = {
  fetchPOST: fetchPOST,
  getAgama : getAgama,
  getStatusPerkawinan : getStatusPerkawinan,
  getBahasa : getBahasa,
  getBesaranUpah : getBesaranUpah,
  getKecamatan : getKecamatan,
  getDesKel : getDesKel,
  getPendidikan : getPendidikan,
  getJurusan : getJurusan,
  getBidang : getBidang,
}